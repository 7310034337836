// import { useState } from 'react';
import * as R from 'ramda';
import { Box, Toolbar, Button, ButtonGroup, Select, MenuItem, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';

import { standardSinceMap, extendedSinceMap } from '../state/dataTarget';
import { RangeForm } from './RangeForm';

const stdSinceVals = R.keys(standardSinceMap);
const extSinceVals = R.keys(extendedSinceMap);

const MiniButton = styled(Button)(({ theme, selected }) => ({
  textTransform: 'none',
  padding: theme.spacing(0.25, 1),
  minWidth: 0,
  borderColor: selected ? theme.palette.secondary.dark : theme.palette.primary.dark,
  color: selected ? theme.palette.secondary.dark : theme.palette.primary.dark,
}));

const MiniSelect = styled(Select)(({ theme, hasSelected }) => ({
  lineHeight: 1.75,
  fontSize: '0.8125em',
  borderRadius: 0,
  '& > div': {
    padding: '2px 28px 2px 8px',
    color: hasSelected ? theme.palette.secondary.dark : theme.palette.primary.dark,
  },
  '& > fieldset': {
    borderColor: `${
      hasSelected ? theme.palette.secondary.dark : theme.palette.primary.dark
    } !important`,
    borderWidth: '1px',
  },
  // '&:focused': {
  //   borderWidth: '1px',
  // },
  '& > svg': {
    fill: hasSelected ? theme.palette.secondary.dark : theme.palette.primary.dark,
  },
}));

export const RangeExpander = ({ since, from, to, setValue }) => {
  const setSince = (val) => () => setValue('since', val);

  return (
    <>
      <Toolbar variant="dense" disableGutters sx={{ px: 2, justifyContent: 'space-between' }}>
        <Box>
          <ButtonGroup>
            {stdSinceVals.map((val) => (
              <MiniButton
                size="small"
                variant="outlined"
                selected={since === val}
                onClick={setSince(val)}
              >
                {val}
              </MiniButton>
            ))}
            <MiniSelect
              labelId="extra-since-values-label"
              id="extra-since-values"
              value={since}
              hasSelected={extSinceVals.includes(since)}
              label=""
              onChange={({ target: { value } }) => setValue('since', value)}
              // classes={{ focused: { borderWidth: 1 } }}
            >
              {extSinceVals.map((val) => (
                <MenuItem value={val}>{val}</MenuItem>
              ))}
            </MiniSelect>
            <MiniButton
              size="small"
              variant="outlined"
              selected={since === 'custom'}
              onClick={setSince('custom')}
            >
              CUSTOM
            </MiniButton>
          </ButtonGroup>
        </Box>
      </Toolbar>
      <Collapse in={since === 'custom'}>
        <Box sx={{ px: 2, py: 1 }}>
          <MiniButton onClick={() => (window.TIMERANGECOPY = { from, to })}>COPY</MiniButton>
          <MiniButton
            onClick={R.pipe(
              () => setValue('from', window.TIMERANGECOPY?.from),
              () => setValue('to', window.TIMERANGECOPY?.to),
            )}
          >
            PASTE
          </MiniButton>
          <Box p={1} />
          <RangeForm {...{ from, to, setValue }} />
        </Box>
      </Collapse>
    </>
  );
};
