import { useEffect } from 'react';
import { bi } from 'tri-fp';
import { useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Toolbar,
  TextField as MuiTextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// import { formatISO } from 'date-fns/fp';

import { RangeExpander } from './RangeExpander';
import { defaultLimit } from '../appConfig';
import { formValuesToDataTarget } from '../state/dataTarget';

const TextField = styled(MuiTextField)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const ChannelBar = ({ firstfetch, sendParent, sourceId, dataId, id, single }) => (
  <Toolbar
    // variant="dense"
    disableGutters
    sx={{ px: 2, justifyContent: 'flex-end', bgcolor: 'background.default' }}
  >
    <Box flexGrow={1}>
      <Typography component="span">{firstfetch ? 'Channel' : `${sourceId}.${dataId}`}</Typography>
    </Box>
    <IconButton
      aria-label="delete"
      onClick={() => sendParent({ type: 'REMOVE', id, sourceId, dataId })}
      disabled={single}
    >
      <DeleteIcon />
    </IconButton>
  </Toolbar>
);

const ErrorMsg = ({ error }) =>
  Boolean(error) && (
    <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
      <WarningIcon color="error" sx={{ mr: 1 }} />
      <Typography color="error">{error.message}</Typography>
    </Toolbar>
  );

export const DataFetchForm = ({
  sendParent,
  matches,
  id,
  dataTarget: { since: sinceInit, from: fromInit, to: toInit, sourceId, dataId, limit },
  single,
  error,
  send,
}) => {
  const firstfetch = ['start', 'fetching'].some(matches);
  const fetching = ['fetching', 'refetching'].some(matches);
  const { register, handleSubmit, setFocus, setValue, watch } = useForm({
    defaultValues: { since: sinceInit, from: fromInit, to: toInit },
  });
  const since = watch('since');
  const from = watch('from');
  const to = watch('to');

  useEffect(() => void bi(setFocus)('sourceId'), [setFocus]);

  const onSubmit = (data) => {
    // console.log('DataFetchForm.onSubmit:', frm, tt, typeof frm);
    send({
      type: 'FETCH',
      params: formValuesToDataTarget(data),
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ChannelBar {...{ firstfetch, sendParent, sourceId, dataId, id, single }} />
      <Box display="flex" flexDirection="column" px={2} pt={1}>
        {firstfetch && (
          <>
            <TextField
              required
              id="sourceId"
              label="Source ID"
              defaultValue={sourceId ?? ''}
              inputProps={register('sourceId')}
              fullWidth
            />
            <TextField
              required
              id="dataId"
              label="Data ID"
              defaultValue={dataId ?? ''}
              inputProps={register('dataId')}
              fullWidth
            />
          </>
        )}
      </Box>
      <RangeExpander {...{ since, from, to, setValue }} />
      <Box display="flex" alignItems="center" pb={1} px={2}>
        <TextField
          id="limit"
          label="Limit"
          type="number"
          defaultValue={limit ?? defaultLimit}
          inputProps={register('limit')}
          sx={{ display: '', flexGrow: 1 }}
        />
        <Box p={1} /* spacer */ />
        {fetching ? (
          <CircularProgress />
        ) : (
          <>
            <IconButton
              aria-label="cancel"
              onClick={() => send('CANCEL')}
              sx={{ display: firstfetch ? 'none' : 'inline-flex' }}
            >
              <CancelIcon />
            </IconButton>
            <IconButton aria-label="fetch" type="submit" disabled={fetching}>
              <CloudDownloadIcon />
            </IconButton>
          </>
        )}
      </Box>
      <ErrorMsg error={error} />
    </form>
  );
};
