import { subHours, subDays, subMonths, subYears } from 'date-fns/fp';

import { OBJ } from '../utils/dataUtils';
import { defaultLimit } from '../appConfig';

export const createDataTarget = ({
  sourceId = '',
  dataId = '',
  limit = defaultLimit,
  since = '',
  from = '',
  to = '',
} = OBJ) => ({
  sourceId,
  dataId,
  limit,
  since,
  from,
  to,
});

export const standardSinceMap = {
  '1h': subHours(1),
  '1d': subDays(1),
  '7d': subDays(7),
  '30d': subDays(30),
  '1y': subYears(1),
};
export const extendedSinceMap = {
  '3h': subHours(3),
  '6h': subHours(6),
  '3d': subDays(3),
  '90d': subDays(90),
  '6M': subMonths(6),
  '3y': subYears(3),
};
const sinceMap = { ...standardSinceMap, ...extendedSinceMap };

export const dateToUTCStr = (d) => d.toISOString();

const sinceToFrom = (since) => (sinceMap[since] || subDays(1))(new Date());

export const prepareDataTarget = (dataTarget) => {
  const { since } = dataTarget;
  return {
    ...dataTarget,
    ...(since && since !== 'custom' ? { from: dateToUTCStr(sinceToFrom(since)) } : {}),
  };
};

export const formValuesToDataTarget = ({ from: frm, to: tt, limit: lmt, since: snc, ...rest }) => ({
  ...(!snc || snc === 'custom'
    ? { from: frm ? dateToUTCStr(frm) : '', to: tt ? dateToUTCStr(tt) : '' }
    : { since: snc, from: '', to: '' }),
  limit: parseInt(lmt, 10),
  ...rest,
});
