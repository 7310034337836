import * as R from 'ramda';
import { random, delay } from 'rambdax';

import { replies } from '../utils/mock';

export const getHost = () => window.location.host;
export const getProtocol = () => window.location.protocol;
export const isLocalhost = () => getHost().startsWith('localhost');
// export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
const mockMode = isLocalhost();

export const urlParams = (obj) =>
  Object.entries(obj)
    .map((pair) => pair.join('='))
    .join('&');

export const appUrl = (app, relativePath, params) =>
  `${getProtocol()}//${getHost().replace(/[^.]+/, app)}${relativePath}${
    params ? `?${urlParams(params)}` : ''
  }`;

export const mocked = (mock) => (fn) => mockMode ? (R.is(Function)(mock) ? mock() : mock) : fn();

export const mockedAsync = async (mock, fnP) =>
  !mockMode ? fnP() : delay(1000).then(R.is(Function)(mock) ? mock : () => mock);

// let uglyIdForLogging = 0; // eslint-disable-line

export const fetchCheckedJson = async (url, { refreshTokenFunc, ...options } = {}) => {
  // const msgId = uglyIdForLogging;
  // uglyIdForLogging += 1; // eslint-disable-line
  // console.log(`Will fetch [${msgId}]:`, url);
  const res = await fetch(url, options);
  const { ok, status, statusText } = res;
  // console.log(`Response status [${msgId}]:`, status, ok ? 'ok' : 'not ok');
  const json = res.json ? await res.json() : {};
  if (!ok) {
    if (status === 401 && refreshTokenFunc) {
      //  && json.error === TOKEN_IS_EXPIRED
      return refreshTokenFunc().then(() => fetchCheckedJson(url, options));
    }
    const error = Error(`Fetch failed: ${status} ${statusText}`);
    error.response = { status, statusText };
    return Promise.reject(error);
  }
  // console.log(`JSON data [${msgId}]:`, JSON.stringify(json, null, 2));
  return json;
};

const getParams = ({ sourceId, dataId, from, to, limit, ascending }) => ({
  dataSourceId: sourceId,
  dataId,
  projection: {
    timestamp: 1,
    data: 1,
  },
  from,
  to,
  limit,
  ascending,
  // sampleInterval: 60, // from range, but for dev none or input
});

export const example = `
{
  "dataSourceId": "dp2200",
  "dataId": "height-backend",
  "limit": 10
}dt50-2b215552
distance
utilization, production-speed-average,
`;
// "ascending": true,"dataId": "distance","dataSourceId": "dt50-2b215552","from": "2021-05-23T22:34Z","limit": 10000,"projection": {"timestamp": 1, "data": 1},"to": "2021-05-24T22:34Z"

const getAscending = ({ from }) => Boolean(from);

const url = 'https://data-history.dev.atosis.online/read';

export const fetchData = (params = {}) => {
  const { dataId, sourceId } = params;
  if (!dataId || !sourceId) return undefined;
  const ascending = getAscending(params);
  const body = getParams({ ...params, ascending });
  console.log({ body });
  const mockFn = () => {
    const ix = R.mathMod(parseInt(dataId, 36) + parseInt(sourceId, 36), replies.length + 1);
    const noFail = Boolean(random(0, 4));
    const res = noFail ? replies[ix] : Promise.reject(Error('Failed to fetch'));
    console.log({ res });
    return res;
  };
  const liveFn = () =>
    fetchCheckedJson(!mockMode && isLocalhost() ? url : appUrl('data-history', '/read'), {
      method: 'POST',
      // mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }).then(R.when(R.propEq('count', 0), () => Promise.reject(Error('Empty data returned'))));
  return mockedAsync(mockFn, liveFn).then(R.evolve({ data: R.when(() => !ascending, R.reverse) }));
};
