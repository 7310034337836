import { DateTimePicker as MuiDateTimePicker } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
// import { Controller } from 'react-hook-form';
// import * as R from 'ramda';
import { addHours } from 'date-fns/fp';

const DateTimePicker = styled(MuiDateTimePicker)({});

const NarrowingPicker = ({ label, ...rest }) => {
  // console.log('NarrowingPicker.rest:', rest);
  return (
    <DateTimePicker
      label={label}
      // renderInput={({ inputProps, ...props }) => {
      renderInput={(props) => {
        return (
          <TextField
            // InputLabelProps={{ shrink: true }}
            fullWidth
            // inputProps={{
            //   ...inputProps,
            // }}
            {...props}
          />
        );
      }}
      ampm={false}
      inputFormat="yyyy-MM-dd HH:mm"
      mask="____-__-__ __:__"
      clearable
      // showToolbar
      showTodayButton
      maxDateTime={addHours(1)(new Date())}
      {...rest}
    />
  );
};

export const RangeForm = ({ from, to, setValue }) => (
  <Box display="flex" flexDirection="column">
    <NarrowingPicker
      label="From"
      /* eslint-disable no-null/no-null */
      value={from || null}
      /* eslint-enable no-null/no-null */
      onChange={(date) => {
        // console.log('RangeForm.onChange(From):', date);
        setValue('from', date, { shouldDirty: true, shouldTouch: true });
      }}
    />
    <Box p={1} />
    <NarrowingPicker
      label="To"
      /* eslint-disable no-null/no-null */
      value={to || null}
      /* eslint-enable no-null/no-null */
      onChange={(date) => setValue('to', date, { shouldDirty: true, shouldTouch: true })}
      minDateTime={from}
    />
  </Box>
);
